$primary: #fcce0c;
$orange: #f85d49;
$orange-hover: #f85d49;
$primary-hover: #ff715b;
$gray: #e8e8e8;
$blue: #4a90e2;
$color-primary: #c10000;
$color-gray: #999;
$color-rakuten: #c10000;
$color-yshop:#ff9000;
$color-pink: #cb09d8;
$color-green: #36a200;
$color-blue: #038fde;

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto-Regular';
    // background: #fff !important;
    background-color: #f6f6f6;
    color: #393c45 !important;
}
.link {
    cursor: pointer;
}
.mr-l-10 {
    margin-left: 10px;
}
.navbar-toggle {
    border: none;
}

.product__variant__plus{
  display: flex;
  justify-content: flex-end;
  // width: 50px;
  // height: 40px;
}
.product__col__name{
  display: inline-block;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.box__arr__img{
  position: relative;
  .buttom__img__minus{
    display: none ;
    cursor: pointer;
    position: absolute;
    color: #fff;
    border: 1px solid #fff;
    padding: 0px;
    top: -8px;
    right: -8px;
    background: red;
    text-align: center;
    padding: 0px;
    line-height: 20px;
    border-radius: 50% !important;
    z-index: 1;
    padding: 4px;
    justify-content: center;
    .upload-icon {
      margin: auto;
      font-size: 26px;
      color: #ffff;
    }
    &:hover{
      background: #e05355;
    }

  }
  &:hover{
    .buttom__img__minus{
      display: flex;
    }
  }
}

.product__variant__price{
  padding-top: 10px;
  .product__variant__head{
    display: flex;
    align-items: center;
    // height: ;
    border: 1px solid #d9d9d9;
    border-radius: 4px 4px 0 0;
    .product__variant__name{
      width: 250px;
      padding: 10px;
      display: block;
      text-align: center;
      border-right: 1px solid #d9d9d9;
    }
    .product__variant__info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      width: calc(100% - 250px);

      .product__variant__price{
        padding: 10px;
        display: block;
        width: calc(100% - 50px);
        width: 9;
        text-align: center;
      }
      .product__variant__plus{
        margin-left: -20px;
      }
    }
  }
  .product__item__variant{
    display: flex;
    border: 1px solid #d9d9d9;
    border-top: none;
    position: relative;
    &:nth-last-child(1){
      border-radius: 0 0 4px 4px;
    }
    .product__position{
      position: absolute;
      right: -17px;
      top: 62%;
      // width: 50px;
      // height: 50px;
      z-index: 22;
      -ms-transform: translate(0,-50%);
      transform: translate(0,-50%);
    }
    .product__variant__attribute{
      width: 250px;
      padding: 10px;
      display: block;
      border-right: 1px solid #d9d9d9;
    }
    .product__variant__input__attribute{
      width: calc(100% - 250px);
      padding: 10px 30px 10px 10px;
      .product__variant__flex{
        display: flex;
        margin-left: -10px;
        margin-right: -10px;
        .product__variant__item{
          width: 33.33%;
          padding: 0 5px;
        }
      }
    }
  }
}
.err__tag{
  color: #ff4d4f;
  display: block;
}

.site-tag-plus{
  margin-bottom: 10px;
   padding: '4px 6px';
    border-radius: 8px;
     background: 'none'
}

select, input, textarea {
    outline: none;
    border-radius: 2px;
}
.ant-input, .ant-btn {
    border-radius: 2px;
}
.ant-tag{
  padding: 6px;
  border-radius: 6px;
  background: none;

}
.site-tag-plus{
  &::after{
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: '*';
  }
}
.btn-facebook {
    outline: none;
    background-color: #4d75b9;
    margin-bottom: 5px;
    &:hover, &:focus, &:active {
        outline: none !important;
        background-color: #3c5e97;
        color: #fff;
    }
}

.btn-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
}

.btn-google {
    outline: none !important;
    color: #fff !important;
    background-color: #e5101d !important;
    &:hover, &:focus, &:active {
        outline: none !important;
        background-color: #bc000b !important;
    }
}

.anticon {
    vertical-align: 0px !important
}

.upload__list__array{
  display: flex;
  flex-wrap: wrap;
  // margin-left: -15px;
  // margin-right: -15px;
  min-height: 160px;
  border: 1px dashed #ed0d0d;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  &:hover{
    background: #ffecf2;
  }
  .upload__item__flex{
    width: 33.33%;
    padding: 10px;
    position: relative;
    &:hover{
      .remove__image__arr{
        display: block;
      }
    }
    .remove__image__arr{
      border: none;
      display: none;
      cursor: pointer;
      border-radius: 15px;
      width: 22px;
      height: 22px;
      position: absolute;
      color: #fff;
      border: 1px solid #fff;
      padding: 0px;
      top: 5px;
      right: 5px;
      background: red;
      text-align: center;
      padding: 0px;
      line-height: 20px;
      border-radius: 50% !important;
      z-index: 111;
      &:hover{
        border: 1px solid red;
        color: red;
        background: #fff;
      }
    }
  }
  .upload__icon{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 26px;
    height: 26px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%,-50%);
    .upload__buttom__icon{
      font-size: 26px;
      color: #ed0d0d;
      width: 100%;
      height: 100%;
    }
  }

}

.item-profile {
    .ant-dropdown-menu {
        border-radius: 2px;
    }
    .ant-dropdown-menu-item {
        height: 35px;
        i {
            color: $color-gray;
            margin-right: 10px;
        }
        &:hover {
            span {
                color: $primary;
            }
        }
    }
    hr {
        margin: 0px;
    }
}

.product-image {
    position: relative;
    z-index: 99;
    padding-top: 110%;
    .position-image {
        position: absolute !important;
        top: 0px;
        left: 0px;
        height: 100% !important;
        width: 100% !important;
        // div {
        //     height: 100% !important;
        //     // width: auto;
        //     // max-width: 100%;
        // }
        // img {
        //     width: 100%;
        //     height: 100% !important;
        //     max-height: 100%;
        //     object-fit: cover;
        //     position: absolute;
        //     left: 50%;
        //     top: 50%;
        //     transform: translate(-50%,-50%);
        // }
    }
}
.img-zoom-container {
    position: relative;
    width: 100%;
    height: 100%;
    img{
        width: 100%;
    }
    .img-zoom-lens {
        position: absolute;
        border: 1px solid #d4d4d4;
        width: 20%;
        height: 20%;
        // display: none;
    }
    .img-zoom-result {
        position: absolute;
        border: 1px solid #d4d4d4;
        left: calc(100% + 15px);
        top: 0px;
        height: 100%;
        width: 100%;
        // display: none;
    }
    &:hover{
        .img-zoom-result {
            display: block;
        }
        .img-zoom-lens {
            display: block;
        }
    }
  }

.flexslider {
    padding: 0 25px;
    position: relative;
    .left {
        position: absolute;
        left: 0px;
        line-height: 70px;
        padding: 0px;
        border: none;
        background: none;
        outline: unset;
        cursor: pointer;
        i {
            border-radius: 50%;
            padding: 2px;
            color: $primary;
        }
        &:hover {
            i {
                background: $primary;
                color: #fff;
            }
        }
    }
    .right {
        position: absolute;
        right: 0px;
        line-height: 70px;
        padding: 0px;
        border: none;
        background: none;
        outline: unset;
        cursor: pointer;
        i {
            border-radius: 50%;
            padding: 2px;
            color: $primary;
        }
        &:hover {
            i {
                background: $primary;
                color: #fff;
            }
        }
    }
    .thumbs {
        width: 100%;
        overflow: hidden;
        margin: 5px 0px;
        .images-thumbs{
            display: inline-flex;
            padding: 0px;
            margin: 0px;
            transition: 1s;
            li {
                cursor: pointer;
                padding: 3px;
                img {
                    width: 100%;
                    height: 100%;
                }
                &:hover, .active {
                    border-radius: 2px;
                    border: 1px solid $primary;
                }
            }
        }
    }
}


.head-title {
    border-top: 2px solid #ff5e00;
    background: #fff;
    border-radius: 3px;
    display: inline-block;
    width: 100%;
    .modtitle {
        margin: 0;
        margin-top: -1px;
        text-transform: capitalize;
        font-size: 16px;
        padding: 10px 18px 10px 45px;
        border-radius: 0 0 0 3px;
        color: #fff;
        background: #ff5e00;
        float: left;
        position: relative;
        img {
            background-position: -20px -16px;
            width: 14px;
            height: 17px;
            position: absolute;
            top: 10px;
            left: 20px;
            right: auto;
            bottom: auto;
        }
    }
    .view-more {
        float: right;
        padding: 5px 20px;
        position: relative;
        span {
            color: #e9597e;
            &::before {
                content: "";
                position: absolute;
                height: 5px;
                width: 5px;
                display: none;
                top: -4px;
                border-right: 5px solid transparent;
                border-top: 5px solid #e9597e;
                border-left: 5px solid transparent;
                border-bottom: 5px solid transparent;
                left: 40%;
            }
        }
    }
}
//

.table-main {
    thead {
        th {
            text-transform: uppercase;
            font-family: "Roboto-Medium";
            color: #1c1c1c;
            font-size: 13px;
        }
    }
    tbody {
        tr {
            font-size: 13px;
        }
    }
}

.header-top-one{
    background-color: #f2f2f2;
    border-bottom: 1px solid #e8e8e8;
    .list-contact{
        font-weight: 700;
         padding: 6px 0;
         a{
            // color: $linkColor;
            &:hover{
                text-decoration: underline;
            }
         }
    }
    .header-top-right {
        // text-align:$right;
        line-height: 38px;
        ul.top-link{
            list-style: none;
            margin:0;
            vertical-align:top;
            > li{
                padding: 0;
                display: inline-block;
                position: relative;
                list-style: outside none none;
                margin-right: 18px;
                float: left;
                &:after{
                    content: '';
                    background: #ddd;
                    width: 1px;
                    height: 15px;
                    position: absolute;
                    top: 12px;
                    right: -8px;
                }
                &:last-child{ margin: 0;
                    &:after{ display: none; }
                }
                &.login{
                    a{
                        padding: 0 3px;
                        &.res{ padding-left: 0; }
                    }
                }
                > a{
                    display: inline-block;
                    padding: side-values(0 8px 0 0);
                    position: relative;
                    color: #555;
                    font-size: 12px;
                    line-height: 20px;
                    &:hover{
                        color: red;
                    }
                    &:before{
                        font-family: 'FontAwesome';
                        margin-right: 7px;
                    }
                    span.fa-angle-down{
                        position: relative;
                        left: 5px;
                    }
                }
                &.account > a:before{content: "\f007";}
                &.wishlist{
                    a:before{content: "\f004"; }
                }
                &.checkout{
                    a:before{content: "\f045";}
                }
                .btn-group{
                    display: block; font-size: 12px;
                    .btn-link{ padding: 0; border: none;
                         img{
                            margin-top: -3px;
                        }
                     }
                    .text{ display: none; }
                    .dropdown-menu{  line-height: 20px;
                        img{
                            margin-top: -3px;
                        }
                    }
                }
                ul.dropdown-link{position: absolute; background: #fff; box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);  opacity: 0; padding: 0px 10px;
                    top: 33px;  transition:top 0.3s ease 0s, opacity 0.3s ease 0s; width: 200px; z-index: -1; text-align: left; left: 0;
                    li{display: block; border-left: 0;
                    a{display: block; padding: 5px 0 4px; border-bottom: solid 1px #efefef; font-size: 11px; line-height: 18px; color: #555;border-right: 0;
                        &:hover{color: red;}
                        &:after{display: none;}
                    }

                    &:last-child a{border-bottom: 0;}
                    }
                }
                &.account:hover  {
                    ul.dropdown-link {top: 34px; z-index: 9999; opacity: 1; border-top: 1px solid rgba(200, 199, 199, 0.3);}
                }
            }
        }
    }
}
